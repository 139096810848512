.subscribe-a {
  text-align: center;
}

.subscribe-button {
  width: 100%;
  padding: 0.8rem 0.5rem;
  background: rgba(100, 120, 129, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  letter-spacing: 0.5px;
}

.NAME, .EMAIL {
  width: 100%;
  height: 3rem;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: 0.5px;
    color: #999999;
  }
}

.NAME {
  flex: 0 0 calc(50% - 1rem);
}

.EMAIL {
  margin-top: 2rem;
}

.subscribe-inputbox {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
}

#mc_embed_signup {
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
  text-align: center;
  margin-top: 25px;
  width: 80%;

  .button {
    width: 100%;
    margin: 0px;
    background-color: rgba(255, 152, 0, 0.5);
    text-align: center;

    &:hover {
      background-color: rgba(255, 152, 0, 0.7);
    }
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3c494f;

  > p {
    font-style: normal;
    font-weight: 600;
    font-size: 2.7rem;
    line-height: 2.25rem;
    color: #fff;
  }

  @media (max-width: 840px) {
    padding: 4rem 3vw !important;

    > p {
      font-size: 2.6rem;
      line-height: 3rem;
      margin: 20px 0 10px 0;
    }
  }

  @media (min-width: 768px) {
    > p {
      margin: 0 25px;
    }

    .NAME {
      height: 3rem;
    }

    .EMAIL {
      width: 100%;
      margin-top: 2rem;
    }
  }

  @media (max-width: 768px) {
    .EMAIL {
      width: 100%;
    }

    .email-div {
      display: flex;
      justify-content: center;
    }

    .subscribe-inputbox {
      padding-left: 6px;
      flex-direction: column;
    }

    #mc_embed_signup {
      clear: left;
      font: 14px Helvetica, Arial, sans-serif;
      text-align: center;
      margin-top: 10px;
    }

    > p {
      font-size: 2.6rem;
      line-height: 2.5rem;
      margin: 20px 0 10px 0;
    }
  }
}

.box1 {
  flex-wrap: wrap;
}

.heading {
  text-align: center;
  font-weight: bold;
}

.sub-heading {
  margin: 0rem auto 2rem auto;
  font-size: 1.2rem;
  text-align: center;
}

.bg-grey-300 {
  background-color: #f1f5f9;
  width: auto;
  margin: 3%;
  padding: 20px;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;

  + .dark {
    background-color: red;
  }
}

.bg-single {
  background-color: #fff3c5;

  .button {
    background-color: #ebc017;

    &:hover {
      background-color: #e7cc5f;
    }
  }
}

.button {
  background-color: var(--brand-color-primary) !important;
  border-radius: 0.25rem;
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none !important;
  margin-left: 6px;
  border: 0;

  &:hover {
    background-color: #00d3a9;
    color: #fff;
  }
}

.p-normal {
  font-size: 16px !important;
}

.mailing-row {
  display: inline;
  width: 100%;
  justify-content: center;
}

.mail-link-div {
  margin-top: 7px !important;
}

a.saffron {
  color: #ebc017;
  font-size: large;
}

.mailing-container {
  display: flex;
  flex-wrap: wrap;
  --flex-gap: var(--size-4);
  margin-left: calc(-1 * var(--flex-gap));
  margin-top: calc(-1 * var(--flex-gap));
  justify-content: space-between;
  align-items: center;
}

.mailing-btn {
  align-self: flex-end;
  margin-top: auto;
}

@media (min-width: 1076px) {
  .bg-grey-300 {
    margin: 2%;
  }

  .bg-single {
    padding-right: 2%;
  }

  .mail-link-div {
    margin-top: 0px !important;
  }
}

@media (min-width: 802px) and (max-width: 1075px) {
  .bg-grey-300 {
    margin: 3%;
  }

  .bg-single {
    background-color: yellow;
    padding-right: 0px;

    .button {
      margin: 2px 30px 0 6px;
    }
  }

  .mail-link-div {
    margin-top: 0px !important;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .bg-single {
    width: 40%;
  }
}

@media (min-width: 600px) {
  .mailing-row {
    display: inline-flex;
    width: 100%;
    justify-content: center;
  }

  .mailing-row-single {
    display: inline-flex;
    width: 100%;
    justify-content: center;
  }

  .bg-grey-300 {
    width: 43%;
    margin: 2%;
  }
}
